import { COLORS } from '.';

export const TYPOGRAPHY = {
  sectionText: `
    font-size: 17px;
    line-height: 28px;
    color: ${COLORS.black2};
  `,
  sectionTextMobile: `
    font-size: 17px;
    line-height: 28px;
    
  `,
  sectionTitle: `
    font-size: 48px;
    line-height: 55px;
    font-weight: 600;
    color: ${COLORS.black2};
    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 41px;
    }
  `,
};
