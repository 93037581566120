import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

interface ImageProps {
  localFile: {
    childImageSharp: any | null;
    extension: string;
    publicURL: string;
  };
  alt: string;
  className?: string;
}
const Image: FC<ImageProps> = ({ localFile, alt, className }) => {
  return (
    <>
      {!localFile.childImageSharp && localFile.extension === 'svg' ? (
        <img className={className} src={localFile.publicURL} alt={alt} />
      ) : (
        <GatsbyImage
          className={className}
          image={localFile.childImageSharp.gatsbyImageData}
          alt={alt}
        />
      )}
    </>
  );
};

export default Image;
