import React, { FC } from 'react';
import styled from 'styled-components';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';

const MainLayout: FC = ({ children }) => {
  return (
    <Root>
      <Header />
      {children}
      <Footer />
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

export default MainLayout;
