import { graphql, useStaticQuery } from 'gatsby';

export interface SocialNetworkField {
  id: string;
  title: string;
  fields: {
    link: string;
    icon: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

export function useSocialNetworks(): SocialNetworkField[] {
  const { allWpSocialNetwork } = useStaticQuery(graphql`
    query GET_SOCIAL_NETWORKS {
      allWpSocialNetwork {
        nodes {
          id
          title
          fields: socialNetworksFields {
            link
            icon {
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
      }
    }
  `);

  return allWpSocialNetwork.nodes;
}
