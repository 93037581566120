import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import FooterLogo from '../../assets/images/svg/FooterLogo';
import { COLORS, CONTACTS_ENUM } from '../../constants';
import { useContactUs } from '../../hooks/useContactUs';
import {
  FooterMenuItemValue,
  useFooterMenus,
} from '../../hooks/useFooterMenus';
import { usePolicies } from '../../hooks/usePolicies';
import { Container } from '../Container';
import { Socials } from './components/Socials';

const getLink = (item: FooterMenuItemValue) => {
  if (item.connectedNode) {
    return <Link to={item.path}>{item.label}</Link>;
  } else {
    return (
      <a href={item.path} target="_blank">
        {item.label}
      </a>
    );
  }
};

const Footer: FC = () => {
  const contactUs = useContactUs();
  const policies = usePolicies();
  const emailData = contactUs.find(item => item.title === CONTACTS_ENUM.email);
  const phoneData = contactUs.find(item => item.title === CONTACTS_ENUM.phone);
  const addressData = contactUs.find(
    item => item.title === CONTACTS_ENUM.address
  );
  const { solutionsMenu, aboutUsMenu } = useFooterMenus();

  return (
    <Root>
      <StyledContainer>
        <SectionLeft>
          <LogoLink>
            <StyledFooterLogo />
          </LogoLink>
          <CopyrightInfoWrap>
            <Copyright>
              © {new Date().getFullYear()} Brands Are Live AG
            </Copyright>
            <PoliciesList>
              {policies.map(item => {
                return (
                  <PoliciesItem key={item.id}>
                    {
                      <PoliciesItemLink href={item.fields.link}>
                        {item.title}
                      </PoliciesItemLink>
                    }
                  </PoliciesItem>
                );
              })}
            </PoliciesList>
          </CopyrightInfoWrap>
          <DesktopSocials />
        </SectionLeft>
        <SectionRight>
          <SectionRightItem>
            <MenuTitle>Solutions</MenuTitle>
            <ul>
              {solutionsMenu.map(item => (
                <MenuItem key={item.id}>{getLink(item)}</MenuItem>
              ))}
            </ul>
          </SectionRightItem>
          <SectionRightItem>
            <MenuTitle>About us</MenuTitle>
            <ul>
              {aboutUsMenu.map(item => (
                <MenuItem key={item.id}>{getLink(item)}</MenuItem>
              ))}
            </ul>
          </SectionRightItem>
          <SectionRightItem>
            <MenuTitle>Contact us</MenuTitle>
            <ul>
              {emailData && (
                <MenuItem>
                  <a href={`mailto:${emailData.fields.info}`}>
                    {emailData.fields.info}
                  </a>
                </MenuItem>
              )}

              {phoneData && (
                <MenuItem>
                  <a href={`tel:${phoneData.fields.info}`}>
                    {phoneData.fields.info}
                  </a>
                </MenuItem>
              )}
              {addressData && (
                <MenuItem>
                  <address>{addressData.fields.info}</address>
                </MenuItem>
              )}
            </ul>
            <MobileSocials />
          </SectionRightItem>
        </SectionRight>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.footer`
  background-color: ${COLORS.red};
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 73px;
  padding-bottom: 57px;
  padding-right: 11.3%;
  @media (max-width: 1300px) {
    padding-right: 50px;
  }
  @media (max-width: 1024px) {
    padding-right: 32px;
  }
  @media (max-width: 768px) {
    align-items: stretch;
    flex-direction: column-reverse;
    padding-top: 67px;
    padding-bottom: 30px;
    padding-right: 16px;
  }
  @media (max-width: 500px) {
    padding-top: 50px;
    padding-bottom: 60px;
  }
`;

const SectionLeft = styled.div`
  padding-top: 7px;
  @media (max-width: 768px) {
    margin-top: -42px;
  }
  @media (max-width: 500px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 0;
  }
`;

const LogoLink = styled(Link).attrs(() => ({
  to: '/',
}))`
  display: flex;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    margin-bottom: 22px;
  }
  @media (max-width: 500px) {
    margin-bottom: 0;
  }
`;

const StyledFooterLogo = styled(FooterLogo)`
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 500px) {
    width: 93px;
    height: 93px;
  }
`;

const CopyrightInfoWrap = styled.div`
  margin-bottom: 25px;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column-reverse;
    text-align: right;
  }
`;

const Copyright = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;

  color: ${COLORS.white};
  margin-bottom: 20px;
  @media (max-width: 500px) {
    margin-bottom: 0;
  }
`;

const PoliciesList = styled.ul`
  @media (max-width: 500px) {
    margin-bottom: 14px;
  }
`;

const PoliciesItem = styled.li`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const PoliciesItemLink = styled.a`
  font-size: 12px;
  line-height: 14px;
  color: ${COLORS.white};
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;

const DesktopSocials = styled(Socials)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const SectionRight = styled.div`
  display: flex;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
  @media (max-width: 500px) {
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
`;

const SectionRightItem = styled.div`
  margin-right: 100px;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 900px) {
    margin-right: 60px;
  }
  @media (max-width: 768px) {
    margin-right: 40px;
  }
  @media (max-width: 500px) {
    flex-grow: 1;
    margin-right: 0;
    &:last-child {
      width: 100%;
      margin-top: 50px;
    }
  }
`;

const MenuTitle = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: ${COLORS.white};
  margin-bottom: 30px;
  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
`;

const MenuItem = styled.li`
  margin-bottom: 20px;

  a {
    transition: opacity 0.3s;
  }

  a:hover {
    opacity: 0.7;
  }
  &:last-child {
    margin-bottom: 0;
  }
  a,
  address {
    font-size: 14px;
    line-height: 17px;
    color: ${COLORS.white};
    font-style: normal;
  }
  address {
    max-width: 130px;
  }
  @media (max-width: 500px) {
    margin-bottom: 15px;
  }
`;

const MobileSocials = styled(Socials)`
  display: none;
  margin-top: 28px;
  @media (max-width: 768px) {
    display: flex;
  }
  @media (max-width: 500px) {
    margin-top: 15px;
  }
`;

export default Footer;
