import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

interface SeoProps {
  title: string;
  description?: string;
}

const Seo: FC<SeoProps> = ({ title, description }) => {
  return (
    <Helmet
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]}
    >
      <script type="text/javascript">
        {`var _iub = _iub || [];
          _iub.csConfiguration = {
              invalidateConsentWithoutLog: true,
              consentOnContinuedBrowsing: false,
              lang: "en",
              floatingPreferencesButtonDisplay: "bottom-right",
              siteId: 2433185,
              countryDetection: true,
              cookiePolicyId: 56517648,
              localConsentDomain: "brandsarelive.com",
              banner: {
                  acceptButtonDisplay: true,
                  customizeButtonDisplay: true,
                  closeButtonDisplay: false,
                  explicitWithdrawal: true,
                  position: "float-bottom-right",
                  acceptButtonColor: "#FF1D44",
                  acceptButtonCaptionColor: "#FFFFFF",
                  customizeButtonColor: "#212121",
                  customizeButtonCaptionColor: "white",
                  rejectButtonDisplay:true,
                  rejectButtonColor: "#FF1D44",
                  rejectButtonCaptionColor: "white",
                  textColor: "#010101",
                  backgroundColor: "#FFFFFF",
              },
          };`}
      </script>
      <script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/iubenda_cs.js"
        async
      />
    </Helmet>
  );
};

export default Seo;
