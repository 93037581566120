import * as React from 'react';

function Close(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      fill="none"
      viewBox="0 0 20 19"
      {...props}
    >
      <path stroke="#212121" d="M1.354 0.646L19.031 18.324"></path>
      <path
        stroke="#212121"
        d="M0 -0.5L25 -0.5"
        transform="matrix(-.7071 .7071 .7071 .7071 19 1)"
      ></path>
    </svg>
  );
}

export default Close;
