import { graphql, useStaticQuery } from 'gatsby';

export interface FooterMenuItemValue {
  id: string;
  label: string;
  path: string;
  title: string;
  url: string;
  connectedNode: {
    node: {
      id: string;
    };
  } | null;
}

interface FooterMenuReturnedValue {
  solutionsMenu: FooterMenuItemValue[];
  aboutUsMenu: FooterMenuItemValue[];
}

export function useFooterMenus(): FooterMenuReturnedValue {
  const { solutionsMenu, aboutUsMenu } = useStaticQuery(graphql`
    query GET_FOOTER_MENUS {
      solutionsMenu: wpMenu(name: { eq: "Footer Solutions Menu" }) {
        menuItems {
          nodes {
            id
            label
            path
            title
            url
            connectedNode {
              node {
                id
              }
            }
          }
        }
      }
      aboutUsMenu: wpMenu(name: { eq: "Footer About us Menu" }) {
        menuItems {
          nodes {
            id
            label
            path
            title
            url
            connectedNode {
              node {
                id
              }
            }
          }
        }
      }
    }
  `);

  return {
    solutionsMenu: solutionsMenu.menuItems.nodes,
    aboutUsMenu: aboutUsMenu.menuItems.nodes,
  };
}
