import { graphql, useStaticQuery } from 'gatsby';

export interface ContactUsField {
  title: string;
  fields: {
    info: string;
  };
}

export function useContactUs(): ContactUsField[] {
  const { allWpContact } = useStaticQuery(graphql`
    query GET_CONTACT_US {
      allWpContact {
        nodes {
          title
          fields: contactsUsFields {
            info
          }
        }
      }
    }
  `);

  return allWpContact.nodes;
}
