import React, { FC } from 'react';
import styled from 'styled-components';
import { useSocialNetworks } from '../../../../hooks/useSocialNetworks';
import { Image } from '../../../Image';

interface SocialsProps {
  className?: string;
}

const Socials: FC<SocialsProps> = ({ className }) => {
  const socials = useSocialNetworks();

  return (
    <Root className={className}>
      {socials.map(item => (
        <Item key={item.id}>
          <ItemLink href={item.fields.link}>
            <Image localFile={item.fields.icon.localFile} alt={item.title} />
          </ItemLink>
        </Item>
      ))}
    </Root>
  );
};

const Root = styled.ul`
  display: flex;
  align-items: center;
`;

const Item = styled.li`
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
`;

const ItemLink = styled.a.attrs(() => ({
  target: '_blank',
}))`
  display: block;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;

export default Socials;
