export const COLORS = {
  lightGray: '#F2F2F2',
  darkGray: '#CFCFCF',
  darkGray2: '#535353',
  black1: '#121515',
  black2: '#212121',
  black3: '#000',
  red: '#FF1E44',
  white: '#fff',
};
