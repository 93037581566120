import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';

interface ContainerProps {
  className?: string;
  style?: CSSProperties;
}

const Container: FC<ContainerProps> = ({ children, className, style }) => {
  return (
    <Root className={className} style={style}>
      {children}
    </Root>
  );
};

const Root = styled.div`
  max-width: 1440px;
  padding-left: 7.5%;
  padding-right: 7.5%;
  margin: 0 auto;
  @media (min-width: 1440px) {
    padding-left: 108px;
    padding-right: 108px;
  }
  @media (max-width: 1300px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 1024px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default Container;
