import { Link } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { HeaderMenuValue, useHeaderMenu } from '../../hooks/useHeaderMenu';
import { Container } from '../Container';
import { COLORS } from '../../constants';
import Logo from '../../assets/images/svg/Logo';
import Close from '../../assets/images/svg/Close';

const Header: FC = () => {
  const headerMenuItems = useHeaderMenu();
  const [isOpenMenu, seIsOpenMenu] = useState(false);

  useEffect(() => {
    document.documentElement.style.overflow = isOpenMenu ? 'hidden' : '';
  }, [isOpenMenu]);

  return (
    <Root>
      <HeaderContainer>
        <Link to="/">
          <StyledLogo />
        </Link>

        <Menu $isOpenMenu={isOpenMenu}>
          <CloseMenuBtn onClick={() => seIsOpenMenu(false)}>
            <Close />
          </CloseMenuBtn>
          <MenuList>
            {headerMenuItems.map((item: HeaderMenuValue) => {
              return (
                <MenuItem key={item.id}>
                  <MenuLink to={item.path}>{item.label}</MenuLink>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>

        <Burger onClick={() => seIsOpenMenu(true)}>
          <span></span>
        </Burger>
      </HeaderContainer>
    </Root>
  );
};

const Root = styled.header`
  background-color: ${COLORS.lightGray};
  border-bottom: 1px solid ${COLORS.darkGray};
`;

const StyledLogo = styled(Logo)`
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
  @media (max-width: 767px) {
    width: 92px;
    height: auto;
  }
`;

const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 21px;
  padding-bottom: 8px;
  /* padding-right: 16.31%; */

  @media (max-width: 1024px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media (max-width: 767px) {
    padding: 25px 16px 20px 16px;
  }
`;

const Menu = styled.nav<{ $isOpenMenu: boolean }>`
  @media (max-width: 767px) {
    padding-top: 85px;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 375px;
    background-color: ${COLORS.lightGray};
    transition: transform 0.4s ease;
    transform: ${({ $isOpenMenu }) =>
      $isOpenMenu ? 'none' : 'translateX(100%)'};
  }
  @media (max-width: 600px) {
    max-width: none;
    transition-duration: 0.25s;
  }
`;

const MenuList = styled.ul`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const MenuItem = styled.li`
  margin-right: 90px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }

  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;

    border-top: 1px solid ${COLORS.darkGray};
    &:last-child {
      border-bottom: 1px solid ${COLORS.darkGray};
    }
  }
`;

const MenuLink = styled(Link)`
  color: ${COLORS.black1};
  font-size: 16px;
  line-height: 19px;
  font-weight: 300;
  text-transform: capitalize;
  @media (max-width: 767px) {
    display: block;
    width: 100%;
    padding: 35px 20px 35px 50px;
    font-size: 24px;
    line-height: 29px;
  }
  @media (max-width: 374px) {
    padding-left: 20px;
  }
`;

const Burger = styled.button`
  display: none;
  width: 25px;
  height: 17px;

  align-items: center;
  justify-content: center;
  span {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: ${COLORS.black2};
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: ${COLORS.black2};
      left: 0;
    }
    &::before {
      top: -8px;
    }
    &::after {
      bottom: -8px;
    }
  }
  @media (max-width: 767px) {
    display: flex;
    padding: 11px;
    box-sizing: content-box;
  }
`;

const CloseMenuBtn = styled.button`
  display: none;
  position: absolute;
  top: 27px;
  right: 20px;
  padding: 10px;
  @media (max-width: 767px) {
    display: block;
  }
`;

export default Header;
