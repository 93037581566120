import { graphql, useStaticQuery } from 'gatsby';

export interface PoliciyData {
  id: string;
  title: string;
  fields: {
    link: string;
  };
}

export function usePolicies(): PoliciyData[] {
  const { allWpPoliciy } = useStaticQuery(graphql`
    query GET_ALL_POLICIES {
      allWpPoliciy {
        nodes {
          id
          title
          fields: policiesFields {
            link
          }
        }
      }
    }
  `);

  return allWpPoliciy.nodes;
}
