import { graphql, useStaticQuery } from 'gatsby';

export interface HeaderMenuValue {
  label: string;
  path: string;
  id: string;
  url: string;
}

export function useHeaderMenu(): HeaderMenuValue[] {
  const { wpMenu } = useStaticQuery(graphql`
    query GET_HEADER_MENU {
      wpMenu(name: { eq: "Header" }) {
        id
        menuItems {
          items: nodes {
            id
            label
            path
            url
          }
        }
      }
    }
  `);

  return wpMenu.menuItems.items;
}
